import type { Ref } from 'vue'

export default function (itemsPerPage: Ref, totalItems?: Ref) {
  const isInvalid = ref(false)
  const route = useRoute()
  const page = ref(route.query.page
    ? isArray(route.query.page)
      ? route.query.page[0]
      : route.query.page
    : '1')

  watch(() => route.query, () => {
    if (route.query.page) {
      page.value = isArray(route.query.page)
        ? route.query.page[0]
        : route.query.page
    } else {
      page.value = '1'
    }
  })

  const pageRange = computed(() => {
    const parts = page.value.split('-')
    const part1 = Number(parts[0])
    const part2 = Number(parts[1])

    if (isNaN(part1) || (parts[1] && isNaN(part2))) {
      isInvalid.value = true
      throw new Error('Invalid page')
    }

    return [part1, part2]
  })

  const currentPage = computed(() => pageRange.value[1] ? pageRange.value[1] : pageRange.value[0])

  const totalPages = computed(() => Math.ceil(totalItems.value / itemsPerPage.value))

  const displayedPages = computed(() => {
    return pageRange.value[1]
      ? (pageRange.value[1] - pageRange.value[0] + 1)
      : 1
  })

  const prev = computed(() => Math.max(1, currentPage.value - 1))
  const next = computed(() => Math.min(currentPage.value + 1, totalPages.value))

  const batchSize = computed(() => itemsPerPage.value * displayedPages.value)
  const batchStart = computed(() => (pageRange.value[0] - 1) * itemsPerPage.value)

  return {
    currentPage,
    pageRange,
    totalPages,
    displayedPages,
    prev,
    next,
    batchSize,
    batchStart,
    page,
    isInvalid,
  }
}
